"use client";

import { useEffect } from "react";
import { useSearchParams, usePathname, useRouter } from "next/navigation";

import { useSessionStorage } from "@/hooks/useStorage";
import { Nullable } from "@/types/common";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  setPromoCode,
  setSelectedAspectRatio,
  setViewMode,
  getNotifications,
} from "@/redux/actions";
import { useGenContext } from "@/context/GenContext";
import { imageTracker } from "@/utils/imageTracker";
import { pushToDataLayer } from "@/utils/ga";

const ClientScripts = () => {
  const dispatch = useAppDispatch();
  const alerts = useAppSelector((state) => state.alert.alertDialogs);
  const router = useRouter();
  const pathname = usePathname() as string;
  const search = useSearchParams();
  const promoCodeQuery = search?.get("promoCode");
  const { dispatch: genDispatch } = useGenContext();
  const user = useAppSelector((state) => state.user.user);
  const [metaAds, setMetaAds] = useSessionStorage("MetaAds", false);
  const [_, setGoogleAds] = useSessionStorage("GoogleAds", false);

  useEffect(() => {
    const draftSearch = localStorage.getItem("draftPrompt");
    if (user && draftSearch) {
      genDispatch({ type: "SET_PROMPT", payload: draftSearch });
      localStorage.removeItem("draftPrompt");
    }

    const prompt = search?.get("prompt");
    if (prompt) {
      genDispatch({ type: "SET_PROMPT", payload: prompt });
    }
  }, [user]);

  useEffect(() => {
    if (promoCodeQuery) {
      dispatch(setPromoCode(promoCodeQuery as string));
    } else if (localStorage.promoCode) {
      const newPromoCode = localStorage.getItem("promoCode");
      dispatch(setPromoCode(newPromoCode as string));
    }
  }, [promoCodeQuery]);

  useEffect(() => {
    // Initial app load
    if (user) {
      pushToDataLayer({
        event: "set_user_id",
        user_id: user.id?.toString(),
      });

      if (user.temporaryLogin) {
        router.push("/set-password");
      }
    }

    if (user && user?.subscriptionType !== "free") {
      const selectedAspectRatio = localStorage.getItem("selectedAspectRatio");
      if (selectedAspectRatio) {
        dispatch(setSelectedAspectRatio(selectedAspectRatio));
      }

      const viewMode = user.defaultViewMode;
      dispatch(setViewMode(viewMode));
    }
  }, [user]);

  useEffect(() => {
    let interval: Nullable<ReturnType<typeof setInterval>> = null;
    // Replace with websocket
    const dispatchFunction = () => {
      if (process.env.NEXT_PUBLIC_NOTIFICATIONS_API_URL) {
        dispatch(getNotifications());
      }
    };

    dispatchFunction();

    interval = setInterval(dispatchFunction, 10000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    imageTracker.init();
  }, []);

  // FIXME: This is a temporary solution to check for deployments and maintenance mode
  useEffect(() => {
    let interval: Nullable<ReturnType<typeof setInterval>> = null;

    async function fetchBuildId() {
      try {
        // Disabled for now
        // const response = await fetch("/api/build-id");
        // if (response.ok) {
        //   const data = await response.json();
        //   const clientBuildId = process.env.NEXT_PUBLIC_BUILD_ID;
        //   const serverBuildId = data.buildId;
        //   if (!clientBuildId || !serverBuildId) return;
        //   if (clientBuildId !== serverBuildId && alerts.length === 0) {
        //     dispatch(
        //       addAlertDialog({
        //         title: "New Update Available",
        //         description:
        //           "There is a new site update available. Refresh the page to get the latest version.",
        //         prevButtonText: "Not Now",
        //         nextButtonText: "Refresh",
        //         type: AlertDialogType.UpdateAvailable,
        //       })
        //     );
        //   }
        // }
        // FIXME: This error gets thrown when redeploying existing deployment
        // Needed when enabling maintenance mode
      } catch (error) {
        console.error(error);
        window?.location.reload();
      }
    }

    if (pathname === "/maintenance") {
      if (interval) {
        clearInterval(interval);
      }
    } else {
      interval = setInterval(fetchBuildId, 30000); // Check every 30 seconds
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [alerts]);

  useEffect(() => {
    function setMetaAdsStorage() {
      const urlParams = new URLSearchParams(window.location.search);
      const utmSource = urlParams.get("utm_source");
      if (utmSource === "MetaAds") {
        setMetaAds(true);
      }
    }

    setMetaAdsStorage();
  }, []);

  useEffect(() => {
    function setGoogleAdsStorage() {
      const urlParams = new URLSearchParams(window.location.search);
      const gadSource = urlParams?.get("gad_source");
      if (gadSource === "1") {
        setGoogleAds(true);
      }
    }
    setGoogleAdsStorage();
  }, []);

  return null;
};

export default ClientScripts;
